import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";

function FaqSection(props) {
  const faqItems = [
    {
      question: "What is Verify GPT User?",
      answer:
        "Verify GPT User is a solution that helps capture your custom GPT audience's email addresses. You are also able to perform analytics on your custom GPT usage and review discussions to improve your custom GPT.",
    },
    {
      question: "Do you have a free trial?",
      answer:
        "Yes, all of our plans have a 30 day free trial. You can cancel anytime. Also, if you are not finished evaluating the product and and need to extend your trial, just contact us before it expires!",
    },
    {
      question: "How do I set up Verify GPT User on my custom GPT?",
      answer:
        "The setup is very simple, just sign up for a trial, follow the step by step instructions and wait for users to chat with your GPT.",
    },
    {
      question: "Can I get a refund just in case?",
      answer:
        "Yes we can provide a refund if you are not satisfied with the product. Just contact us within 30 days of your purchase.",
    },
    {
      question: "Do you offer email support?",
      answer:
        "Yes for the Pro and Business plans we provide Email support.",
    },
    {
      question: "What are the different plans available?",
      answer:
        "Starter, Pro and Business. Visit our pricing page to compare them.",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-12 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
          {faqItems.map((item, index) => (
            <div className="prose prose-indigo" key={index}>
              <h4>{item.question}</h4>
              <p>{item.answer}</p>
            </div>
          ))}
        </div>

        {props.showSupportButton && (
          <div className="text-center">
            <Button
              href={props.supportUrl}
              target="_blank"
              size="lg"
              variant="simple"
              startIcon={
                <ArrowTopRightOnSquareIcon className="opacity-50 inline-block w-5 h-5" />
              }
            >
              Contact us if you have any other questions
            </Button>
          </div>
        )}
      </div>
    </Section>
  );
}

export default FaqSection;
