import React from "react";
import Meta from "./../components/Meta";

function FeaturesPage(props) {
  return (
    <>
      <Meta title="Features" description="Features" />
    </>
  );
}

export default FeaturesPage;
