import React, { useState } from "react";
import {
  StarIcon,
  Bars3Icon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import FormAlert from "./FormAlert";
import { useAuth } from "./../util/auth";
import { updateEmail, deleteEmail, useEmailsByOwner } from "./../util/db";

function DashboardEmails(props) {
  const auth = useAuth();

  const {
    data: emails,
    status: emailsStatus,
    error: emailsError,
  } = useEmailsByOwner(auth.user.uid);

  const emailsAreEmpty = !emails || emails.length === 0;

  const canUseStar =
    auth.user.planIsActive &&
    (auth.user.planId === "pro" || auth.user.planId === "business");

  const canGetChatHistory =
    auth.user.planIsActive &&
    (auth.user.planId === "pro" || auth.user.planId === "business");

  const handleStarEmail = (email) => {
    if (canUseStar) {
      updateEmail(email.id, { featured: !email.featured });
    } else {
      alert("You must upgrade to the pro or business plan to use this feature");
    }
  };

  const handleChatHistory = async (emailId, index) => {
    if (canGetChatHistory) {
      props.getChatHistory(emailId)
      var actives = document.querySelectorAll(".email-active");
      for (var i = 0; i < actives.length; i++) {
        actives[i].classList.remove("email-active");
      }
      document.getElementById('email-' +  index).classList.add("email-active");
    } else {
      alert("You must upgrade to the pro or business plan to use the chat history feature");
    }
  };
  

  return (
    <>
      {emailsError && (
        <div className="mb-4">
          <FormAlert type="error" message={emailsError.message} />
        </div>
      )}

      <div>
        <div className="flex justify-between emails-center p-4 border-b border-gray-200">
          <span className="text-xl">Emails</span>
        </div>

        {(emailsStatus === "loading" || emailsAreEmpty) && (
          <div className="p-8">
            {emailsStatus === "loading" && <>Loading...</>}

            {emailsStatus !== "loading" && emailsAreEmpty && (
              <>Nothing yet. You'll see emails here once users chat with your custom GPT.</>
            )}
          </div>
        )}

        {emailsStatus !== "loading" && emails && emails.length > 0 && (
          <>
          <div className="scroll-container-email">
            {emails.map((email, index) => (
              <div
                className={
                  "flex p-4 border-b border-gray-200" +
                  (email.featured ? " bg-yellow-200" : "")
                }
                key={index}
                id={"email-" + index}
              >
                {email.email}
                <div className="flex emails-center ml-auto space-x-3">
                  <button
                    className={
                      "w-5 h-5" +
                      (email.featured ? " text-yellow-500" : "") +
                      (!email.featured ? " text-slate-600" : "")
                    }
                    onClick={() => handleStarEmail(email)}
                  >
                    <StarIcon />
                  </button>
                  <button
                    className="w-5 h-5 text-slate-600"
                    onClick={() => handleChatHistory(email.id, index)}
                  >
                    <Bars3Icon />
                  </button>
                  <button
                    className="w-5 h-5 text-slate-600"
                    onClick={() => deleteEmail(email.id)}
                  >
                    <TrashIcon />
                  </button>
                </div>
              </div>
            ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default DashboardEmails;
