import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Section from "./Section";
import { Link } from "./../util/router";
import TextField from "./TextField";
import Button from "./Button";
import newsletter from "./../util/newsletter";

function Footer(props) {
  const [subscribed, setSubscribed] = useState(false);
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = ({ email }) => {
    setSubscribed(true);
    // Parent component can optionally
    // find out when subscribed.
    props.onSubscribed && props.onSubscribed();
    // Subscribe them
    newsletter.subscribe({ email });
  };

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
      className={props.sticky && "mt-auto"}
    >
      <footer className="container">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          <div>
            <h4 className="text-sm uppercase font-semibold tracking-wider text-gray-400">
              Product
            </h4>
            <nav className="flex flex-col space-y-3 mt-6">
              {[
                { url: "/pricing", name: "Pricing" },
                { url: "/faq", name: "FAQ" },
                { url: "/features", name: "Features" },
              ].map((link, index) => (
                <Link
                  to={link.url}
                  className="font-medium text-gray-600 hover:text-gray-500"
                  key={index}
                >
                  {link.name}
                </Link>
              ))}
            </nav>
          </div>
          <div>
            <h4 className="text-sm uppercase font-semibold tracking-wider text-gray-400">
              VerifyGPTUser
            </h4>
            <nav className="flex flex-col space-y-3 mt-6">
              {[
                { url: "/about", name: "About" },
                { url: "/contact", name: "Contact" },
                { url: "/legal/terms-of-service", name: "Terms of Service" },
                { url: "/legal/privacy-policy", name: "Privacy Policy" },
              ].map((link, index) => (
                <Link
                  to={link.url}
                  className="font-medium text-gray-600 hover:text-gray-500"
                  key={index}
                >
                  {link.name}
                </Link>
              ))}
            </nav>
          </div>
          <div>
            <h4 className="text-sm uppercase font-semibold tracking-wider text-gray-400">
              Social
            </h4>
            <div className="mt-6 flex flex-row space-x-4">
              {[
                {
                  url: "https://twitter.com/Ver1fyGPTUser",
                  icon: (
                    <path d="M24 4.557a9.83 9.83 0 01-2.828.775 4.932 4.932 0 002.165-2.724 9.864 9.864 0 01-3.127 1.195 4.916 4.916 0 00-3.594-1.555c-3.179 0-5.515 2.966-4.797 6.045A13.978 13.978 0 011.671 3.149a4.93 4.93 0 001.523 6.574 4.903 4.903 0 01-2.229-.616c-.054 2.281 1.581 4.415 3.949 4.89a4.935 4.935 0 01-2.224.084 4.928 4.928 0 004.6 3.419A9.9 9.9 0 010 19.54a13.94 13.94 0 007.548 2.212c9.142 0 14.307-7.721 13.995-14.646A10.025 10.025 0 0024 4.557z" />
                  ),
                }
              ].map((link, index) => (
                <Link
                  to={link.url}
                  className="text-gray-400 hover:text-blue-600"
                  target="_blank"
                  rel="noreferrer"
                  key={index}
                >
                  <svg
                    className="inline-block w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    {link.icon}
                  </svg>
                </Link>
              ))}
            </div>
            <h4 className="mt-10 text-sm uppercase font-semibold tracking-wider text-gray-400">
              Join Our Newsletter
            </h4>

            {subscribed === true && (
              <div className="mt-3">You are now subscribed!</div>
            )}

            {subscribed === false && (
              <form
                className="mt-6 flex items-start space-x-2"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="grow">
                  <TextField
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    error={errors.email}
                    size="sm"
                    inputRef={register({
                      required: "Please enter an email address",
                    })}
                  />
                </div>
                <Button type="submit" size="sm">
                  Subscribe
                </Button>
              </form>
            )}
          </div>
        </div>
      </footer>
    </Section>
  );
}

export default Footer;
