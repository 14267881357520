import React from "react";

function DashboardChatHistory(props) {

  //const codeBlock = codeText => {console.log('codeText->', codeText); return (<span className="code-text">{codeText}</span>)};
  let codeBlockStatus = 'none';
  let currentCodeText = '';
  let firstIndex = 0;

  return (
    <>
      <div className="flex justify-between emails-center p-4 border-b border-gray-200">
        <div id="chat_history" className="text-slate-600">
        <span className="text-xl">Chat Details</span>
          <div className="scroll-container-chat">
            {props.chatData.split('\n').map((line, index) => {
              const escapedLine = wrapHtmlInBackticks(escapeHtml(line));
              const boldLine = escapedLine.replace('user:', '<br /><b>user:</b>').replace('system:', '<br /><b>system:</b>');
              const semifinalLine = boldLine.replace(/```([\s\S]*?)```/g, '<span class="code-block">$1</span>');
              let finalLine = semifinalLine;

              if (finalLine.indexOf('```') !== -1 || codeBlockStatus === 'writing') {
                if (codeBlockStatus === 'none') {
                  firstIndex = index;
                }
                codeBlockStatus = 'writing'
                currentCodeText += finalLine.replace(/```/, '') + '</br>';
              }
              if (finalLine.indexOf('```') !== -1 && codeBlockStatus === 'writing' && index !== firstIndex) {
                finalLine = currentCodeText
                codeBlockStatus = 'insert';
                currentCodeText = '';
              }

              finalLine = finalLine.replace(/`([\s\S]*?)`/g, '<span class="code-text">$1</span>');
              if (codeBlockStatus === 'none') {
                return (
                  <p key={index} dangerouslySetInnerHTML={{ __html: finalLine }}></p>
                );
              } else if (codeBlockStatus === 'insert') {
                codeBlockStatus = 'none';
                return (
                  <p className="code-block" key={index} dangerouslySetInnerHTML={{ __html: finalLine }}></p>
                );
              } else {
                return;
              }
            })}<br />
          </div>
        </div>
      </div>
    </>
  );
}

function wrapHtmlInBackticks(text) {
  return text.replace(/<code>([\s\S]*?)<\/code>/g, function(m, p1) {
    return '<code>' + escapeHtml(p1) + '</code>';
  });
}
function escapeHtml(text) {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  };

  return text.replace(/[&<>"']/g, function(m) { return map[m]; });
}

export default DashboardChatHistory;
