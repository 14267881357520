import React from "react";
import Meta from "./../components/Meta";
import Instructions from "./../components/Instructions";

function DeveloperPage(props) {
  return (
    <>
      <Meta title="Developer" description="Intructions for integrate the api" />
      <Instructions 
        title={
            <>
            Developer <span className="font-light">Verify GPT</span>
            </>
        }
        subtitle="Intructions"
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default DeveloperPage;
