import React from "react";

function LegalTerms(props) {
  return (
    <div className="prose prose-a:text-blue-600 max-w-none">
      <h1>Terms of Service</h1>
      <h3>Purpose</h3>
        <p>Welcome to VerifyGPTUser! Our mission is to empower businesses by providing a robust solution designed to capture the email addresses of your custom GPT audience. We strive to enhance user engagement through our unique features such as Gradient Squiggles, UI Buttons, and the Background Slide Arrow. Our commitment is to ensure seamless communication and interaction between your business and your audience, fostering growth and success.</p>
      <h3>Acceptance</h3>
      <p>By using our services, you acknowledge and agree to abide by the terms outlined herein.</p>
      <h2>User Rights and Responsibilities</h2>
      <h3>User Conduct</h3>
      <p>Users must engage in respectful and lawful behavior on our platform and must not abuse or spam the email addresses of custom GPT chat users. Any reports of misuse may lead to service suspension.</p>
      <h3>Account Responsibility</h3>
      <p>Users are responsible for maintaining the security of their accounts and ensuring responsible use.</p>

      <h2>Service Description</h2>
      <h3>Overview</h3>
      <p> At VerifyGPTUser, we provide a solution that helps capture your custom GPT audience's email addresses. You are also able to perform analytics on your custom GPT usage and review discussions to improve your custom GPT.</p>
      <h3>Modifications</h3>
      <p> We reserve the right to modify or discontinue services for enhancement and evolution. Users will be duly notified of significant changes.</p>

      <h2>Payment Terms</h2>
      <h3>Fees</h3>
      <p> Users agree to pay the specified fees for our services. Details on fees are available on our pricing page. Our service may offer discounts or even provide 100% free accounts under certain conditions.</p>
      <h3>Payment Methods</h3>
      <p> We accept payments through credit cards via Stripe. Ensure your account has sufficient funds to avoid disruptions.</p>

      <h2>Privacy Policy</h2>
      <h3>Data Collection</h3>
      <p> We collect email addresses for our newsletter and perform analytics to improve our services. Detailed information is available in our Privacy Policy.</p>
      <h3>Cookies</h3>
      <p> We use cookies to create and maintain an authenticated session. You can manage cookie settings through your account preferences.</p>

      <h2>Intellectual Property</h2>
      <h3>Ownership</h3>
      <p> VerifyGPTUser retains ownership of all intellectual property, ensuring a secure and innovative environment for all users.</p>

      <h2>Termination and Suspension</h2>
      <h3>Grounds for Termination</h3>
      <p> We may terminate or suspend accounts at our discretion for violations including, but not limited to, abuse or spamming activities as described in Section 2.1.</p>
      <h3>Consequences</h3>
      <p> Termination may result in permanent deletion of your account and all associated data.</p>

      <h2>Dispute Resolution</h2>
      <h3>Arbitration or Mediation</h3>
      <p> Disputes will be resolved through arbitration or mediation at our discretion. By using our services, you waive your right to any form of class action or legal dispute outside this mechanism.</p>
      <h3>Jurisdiction</h3>
      <p> There is no governing law providing protections; this service is provided on an "as is" and "as available" basis without warranties of any kind.</p>

      <h2>Miscellaneous</h2>
      <p>This service is the result of personal hobbies and is provided as is without any warranty that it will work consistently or will not stop working at any time. For a more reliable or permanent solution, please contact our team.</p>
      <p>These terms are subject to change at any time without notice. Please review them regularly to stay informed of any updates.</p>
      <p>
        If you do not agree with these Terms of Service, you are prohibited from
        using or accessing {props.domain} and from using any other services we
        provide. We reserve the right to review and amend any of these Terms of
        Service at our sole discretion. Upon doing so, we will update this page.
        Any changes to these Terms of Service will take effect immediately from
        the date of publication.
      </p>

      <h3>Limitations of Use</h3>
      <p>
        By using {props.domain}, you warrant on behalf of yourself, your users,
        and other parties you represent that you will not:
      </p>
      <ol>
        <li>
          modify, copy, prepare derivative works of, decompile, or reverse
          engineer any materials and software contained on the GetTerms.io
          website.
        </li>
        <li>
          remove any copyright or other proprietary notations from any materials
          and software on the {props.domain} website.
        </li>
        <li>etc</li>
      </ol>

      <h3>Last updated</h3>
      <p>This policy was last updated on May 5th, 2024.</p>

    </div>
  );
}

export default LegalTerms;
