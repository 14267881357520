import React, { useState } from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
// import DashboardItems from "./DashboardItems";
import DashboardEmails from "./DashboardEmails";
import DashboardChatHistory from './DashboardChatHistory';
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import { updateEmail, deleteEmail, useEmailsByOwner, getChatHistoryOnce } from "./../util/db";

function DashboardSection(props) {
  const auth = useAuth();

  const [chatHistory, setChatHistory] = useState('');

  const loadChatHistory = async (emailId) => {
    const history = await getChatHistoryOnce(emailId);
    //const chatHistory = history.chat_history.replace(/```([\s\S]*?)```/g, '<span className="code-text">$1</span>')
    const chatHistory = history.chat_history
    console.log('chatHistory->', chatHistory)
    setChatHistory(chatHistory);
  };

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="flex flex-wrap">
          <div className="p-4 w-full md:w-1/2">
            <div className="rounded border border-gray-200">
              <DashboardEmails getChatHistory={loadChatHistory}/>
          </div>
          <div className="rounded border border-gray-200 top-space-5">
            {auth.user.stripeSubscriptionId && (
                <>
                  <div className="padding-5">
                    You are subscribed to the{" "}
                    <strong>{auth.user.planId} plan</strong>.
                  </div>
                  <div className="padding-5">
                    Your plan status is{" "}
                    <strong>{auth.user.stripeSubscriptionStatus}</strong>.
                  </div>
                </>
              )}

              <div className="padding-5">
                You can change your account info{` `}
                {auth.user.stripeSubscriptionId && <>and plan{` `}</>}
                in{` `}
                <Link to="/settings/general">settings</Link>.
              </div>

              {!auth.user.stripeSubscriptionId && (
                <div className="padding-5">
                  You can signup for a plan in{" "}
                  <Link to="/pricing">pricing</Link>.
                </div>
              )}
            </div>
          </div>
          {chatHistory && <div className="p-4 w-full md:w-1/2">
            <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none">
              <DashboardChatHistory chatData={chatHistory}/>
            </div>
          </div> }
        </div>
      </div>
    </Section>
  );
}

export default DashboardSection;
