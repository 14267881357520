import React from "react";

function LegalPrivacy(props) {
  return (
    <div className="prose prose-a:text-blue-600 max-w-none">
      <h1>Privacy Policy</h1>
      <p>
        At VerifyGPTUser, your privacy is of utmost importance to us. We are committed to protecting your personal information and being transparent about what information we collect, use, and share. This policy outlines our practices concerning the personal information we collect from you, or that you provide to us, through this website, {props.domain}, and any associated services.
      </p>

      <h2>Information We Collect</h2>
      <p>
        Personal information is data that can be used to uniquely identify or contact an individual. There are two types of personal information we collect:
        <ul>
          <li><strong>Information you provide:</strong> This includes information that you voluntarily share with us, such as your email address when you sign up for our newsletter or information entered during account registration.</li>
          <li><strong>Automatically collected information:</strong> This is information that is automatically sent to us by your browser or mobile device as you navigate our website.</li>
        </ul>
      </p>

      <h3>Automatically Collected Information</h3>
      <p>
        Our servers log information like your IP address, device type, and browser type every time you access our website. We also collect information about the pages you view, the links you click, and other actions you take on our site.
      </p>

      <h3>Use of Information</h3>
      <p>
        The information we collect helps us improve our website, personalize your experience, and manage our advertising. We may also use it for:
        <ul>
          <li>Providing products and services you request.</li>
          <li>Processing transactions and sending notices about your transactions.</li>
          <li>Communicating with you about offers, promotions, rewards, upcoming events, and other news about products and services offered by VerifyGPTUser and our selected partners.</li>
        </ul>
      </p>

      <h2>Sharing of Information</h2>
      <p>
        We do not share your personal information with third parties except as necessary to carry out our business or your request or as required by law. This may include sharing information with other companies, lawyers, agents or government agencies.
      </p>

      <h2>Your Rights and Choices</h2>
      <p>
        You have the right to access, correct, or delete the personal information we collect. You can also opt out of receiving direct marketing from us at any time. You can exercise these rights by contacting us directly.
      </p>

      <h2>Security of Your Information</h2>
      <p>
        We maintain physical, electronic, and procedural safeguards to protect your information. We also request our service providers to follow the same practices.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy to reflect changes to our information practices. If we make any material changes, we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on this website prior to the change becoming effective.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at support@verifygptuser.com.
      </p>

      <h3>Last updated</h3>
      <p>This policy was last updated on May 5th, 2024.</p>

    </div>
  );
}

export default LegalPrivacy;
