import React from "react";
import {
  CodeBracketIcon,
  CloudIcon,
  EyeDropperIcon,
  BoltIcon,
  EnvelopeOpenIcon,
  CursorArrowRaysIcon,
} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import FeatureIcon2 from "./FeatureIcon2";

function FeaturesSection(props) {
  const features = [
    {
      title: "Effortless Setup",
      description:
        "Effortlessly configure custom actions with a clear and intuitive setup process.",
      icon: CodeBracketIcon,
      iconColor: "orange",
    },
    {
      title: "Instant Integration",
      description:
        "Our platform automatically manages your custom action’s OpenAPI schema, Authorization, and more, with just one click.",
      icon: CloudIcon,
      iconColor: "red",
    },

    {
      title: "Customized Functionality",
      description:
        "Access specialized actions designed specifically for the unique needs of your GPT.",
      icon: EyeDropperIcon,
      iconColor: "emerald",
    },
    {
      title: "Streamlined Simplicity",
      description:
        "Our user-friendly platform eliminates complexity, making it accessible to users of all experience levels.",
      icon: BoltIcon,
      iconColor: "purple",
    },
    {
      title: "Interactive Tracking",
      description:
        "Keep track of user interactions effortlessly with our real-time monitoring tools.",
      icon: EnvelopeOpenIcon,
      iconColor: "blue",
    },
    {
      title: "Seamless Interaction",
      description:
        "Our system ensures a fluid Action to API experience that is minimally disruptive to the end-user.",
      icon: CursorArrowRaysIcon,
      iconColor: "pink",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-5">
          {features.map((feature, index) => (
            <div
              className="group p-5 transition ease-out duration-200 rounded-2xl text-center"
              key={index}
            >
              <FeatureIcon2
                color={feature.iconColor}
                size="large"
                className="mb-12"
              >
                <feature.icon />
              </FeatureIcon2>
              <h4 className="text-lg font-bold mb-2">{feature.title}</h4>
              <p className="leading-relaxed text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default FeaturesSection;
