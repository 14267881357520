import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import StatsSection from "./../components/StatsSection";
import TeamBiosSection from "./../components/TeamBiosSection";
import CtaSection from "./../components/CtaSection";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HeroSection2
        title={
          <>
            We built <span className="font-light">Verify GPT</span>
          </>
        }
        subtitle="We are a passionate team that is quickly growing whose main goals include building the fastest and most secure services to integrate with other GPTs. We also strive to help other teams grow and build businesses using powerful tools."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        leftImage="https://images.unsplash.com/photo-1542744173-8e7e53415bb0?crop=entropy&fit=crop&h=800&w=1280"
        rightImage="/img4.jpg"
      />
      {/* <StatsSection
        title="Trusted by thousands of professionals"
        subtitle="Web developers from all over the world are using our products. Join them and build something amazing!"
        strapline="Inspiring Results"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      /> */}
      <TeamBiosSection
        title="Meet our amazing team"
        subtitle="They are working nonstop behind the scenes to help you build better products, web services and websites."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <CtaSection
        title={
          <>
            Create an account <span className="text-blue-600">today</span>!
          </>
        }
        subtitle="Start capturing your custom GPT audience details to increase your engagement metrics and provide a tailored customer service to fit their needs better!"
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default AboutPage;
